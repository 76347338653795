export const calculateMenuItemPrice = (
  menu_item,
  component_option_value,
  order_item_ingredients
) => {
  let option_price;
  let total_price_for_ingredients = 0;
  if (!!component_option_value?.price) {
    if (!!component_option_value.price) {
      option_price = Number(component_option_value.price);
    } else {
      option_price = 0;
    }
  } else {
    option_price = Number(menu_item.price);
  }
  //      option_price=if component_option_value then component_option_value.price else menu_item.price
  if (order_item_ingredients) {
    for (let order_item_ingredient of order_item_ingredients) {
      const { menu_item_ingredient } = order_item_ingredient;
      if (!!menu_item_ingredient && !!menu_item_ingredient.delta_price) {
        total_price_for_ingredients += Number(
          order_item_ingredient.number === undefined
            ? Number(menu_item_ingredient.delta_price) +
            order_item_ingredient.number
            : Number(menu_item_ingredient.delta_price) *
            order_item_ingredient.number
        );
      }
    }
  }
  return total_price_for_ingredients + Number(option_price);
};

export const calculateOrderItemPrice = (orderItem) => {
  if (orderItem.item_type === 'MenuItem') {
    return calculateMenuItemPrice(
        orderItem.item || orderItem.menu_item,
        orderItem.component_option_value,
        orderItem.order_item_ingredients
      ) * orderItem.number;
  } else {
    return (orderItem.item || orderItem.menu_item).price * orderItem.number;
  }
}

export const resetOrderItemPrice = (orderItem) =>
  orderItem.price = calculateOrderItemPrice(orderItem)

export const orderItemOptionValueName = function(orderItem) {
  if (!!orderItem.component_option_value) {
    return (
      orderItem.component_option_value.name ||
      orderItem.component_option_value.option_name
    );
  }
  if (!!orderItem.option_value) {
    return orderItem.option_value.name;
  }
};

export const orderItemOptionValueDescription = function(orderItem) {
  if (!!orderItem.component_option_value) {
    return (
      orderItem.component_option_value.description ||
      orderItem.component_option_value.option_name
    );
  }
  if (!!orderItem.option_value) {
    return orderItem.option_value.description;
  }
};

export const orderItemDisplayTitle = function(orderItem) {
  if (!!orderItemOptionValueName(orderItem)) {
    return `${ orderItem.menu_item.title }, ${ orderItemOptionValueName(orderItem) }`;
  } else {
    return orderItem.menu_item.title;
  }
};

export const orderItemDisplayDescription = function(orderItem) {
  if (!!orderItemOptionValueDescription(orderItem)) {
    return `${ orderItem.menu_item.description
      }, ${ orderItemOptionValueDescription(orderItem) }`;
  } else {
    return orderItem.menu_item.description;
  }
};

export const orderItemMatchesSelection = function(
  orderItem,
  menu_item,
  component_option_value,
  order_item_ingredients
) {
  if (orderItem.item_id === menu_item.id && orderItem.item_type === 'MenuItem') {
    let component_option_value_id;
    if (!!component_option_value) {
      if (!orderItem.component_option_value_id) {
        return false;
      }
      component_option_value_id =
        component_option_value.component_option_value_id ||
        component_option_value.id;
      if (component_option_value_id !== orderItem.component_option_value_id) {
        return false;
      }
    } else {
      if (!!orderItem.component_option_value_id) {
        return false;
      } //exists in orderItem order item, but not present in the selection
    }

    if (!!order_item_ingredients) {
      if (!orderItem.order_item_ingredients) {
        return false;
      }
      if (
        order_item_ingredients.length !== orderItem.order_item_ingredients.length
      ) {
        return false;
      }
    } else {
      if (!!orderItem.order_item_ingredients) {
        return false;
      } //exists in orderItem order item, but not present in the selection
    }

    let match_found = true;
    for (let order_item_ingredient of order_item_ingredients) {
      match_found = false;
      //passed order_item_ingredients array can be a list of order_item_ingredients or menu_item_ingredients
      const menu_item_ingredient_id =
        order_item_ingredient.menu_item_ingredient_id ||
        order_item_ingredient.id;
      for (let order_item_ing of orderItem.order_item_ingredients) {
        if (
          order_item_ing.menu_item_ingredient_id === menu_item_ingredient_id
        ) {
          match_found = true;
          break;
        }
      }
      if (!match_found) {
        return false;
      }
    } //match in loop did not succeed
    return match_found; //all matches succeeded
  }

  return false;
};


export const orderItemAdjustQuantity = function(orderItem, qty) {
  orderItem.number += 1;
  resetOrderItemPrice(orderItem)
  return orderItem.number;
};

export const orderItemDisplayPrice = function(orderItem) {
  return calculateOrderItemPrice(orderItem)
};

export const buildOrderItemFromMenuItem = (
  menu_item,
  component_option_value,
  menu_item_ingredients,
  quantity,
  location,
  menu
) => {
  const order_item_ingredients = [];
  for (let selected_ingredient_key in menu_item_ingredients) {
    order_item_ingredients.push({
      menu_item_ingredient_id:
        menu_item_ingredients[selected_ingredient_key].id,
      menu_item_ingredient: menu_item_ingredients[selected_ingredient_key],
      weighted_number: menu_item_ingredients[selected_ingredient_key].count,
    });
  }

  const unit_price = calculateMenuItemPrice(
    menu_item,
    component_option_value,
    order_item_ingredients
  );
  if (!!component_option_value) {
    return {
      item_id: menu_item.id,
      item_type: 'MenuItem',
      item: menu_item,
      menu_item_id: menu_item.id,
      menu_item,
      menu,
      unit_price,
      price: unit_price * quantity,
      type: 'OrderItem',
      component_option_value_id: component_option_value.id,
      component_option_value: component_option_value, 
      weighted_number: quantity,
      order_item_ingredients,
      location_id: location.id,
    };
  } else {
    return {
      type: 'OrderItem',
      item_id: menu_item.id,
      item_type: 'MenuItem',
      item: menu_item,
      menu_item_id: menu_item.id,
      menu_item,
      menu,
      unit_price,
      price: unit_price * quantity,
      weighted_number: quantity,
      order_item_ingredients,
      location_id: location.id,
    };
  }
}

export const buildOrderItemFromOrderItem = (order_item) => {
  const {id, ...newOrderItem} = order_item;
  newOrderItem.order_item_ingredients = order_item.order_item_ingredients.map(oii => {
    const {id, order_item_id, ...newOrderItemIngredient} = oii;
    return newOrderItemIngredient;
  })
  return newOrderItem;
}