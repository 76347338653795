import React, { useState, useEffect } from "react";

import { GoogleMapProvider, MapBox, Marker } from "@googlemap-react/core";

import AddressResource from "../../resources/address";
import AddressComponent from "./AddressComponent";
import UserAddressResource from "../../resources/user-address";
import { I18nRails } from "../../../shared/rails-i18n-js";
import sweetalert2 from "sweetalert2";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";

import MapLocationPicker from "./Model/MapLocationPicker";

const withAddresses = (Component) => ({
  Rails,
  propsOwner,
  user,
  setLatLng: propsLatLng,
  propsSelectedAddress,
  propsUnmappedAddress,
  reselectAddress,
  selectAddress
}) => {

  const [showAddressEdit, setShowAddressEdit] = useState({});
  const [selectedAddress, setSelectedAddress] = useState(propsSelectedAddress);
  const [unmappedAddress, setUnmappedAddress] = useState(propsUnmappedAddress);
  const [latLng, setLatLng] = useState(propsLatLng);
  const [_unmappedAddress, set_unmappedAddress] = useState("");
  const [showModal, setShowModal] = useState(true);
  const [isDenmark, setIsDenmark] = useState(Rails.locale_country === "DK");
  const [isPakistan, setIsPakistan] = useState(Rails.locale_country === "PK");
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [owner, setOwner] = useState(propsOwner);
  const [newAddress, setNewAddress] = useState();
  const [mappedAddress, setMappedAddress] = useState();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [address, setAddress] = useState();

  useEffect(() => {
    setOwner(propsOwner);
  }, [propsOwner])

  useEffect(() => {
    setSelectedAddress(propsSelectedAddress)
  }, [propsSelectedAddress]);

  useEffect(() => {
    setUnmappedAddress(propsUnmappedAddress)
  }, [propsUnmappedAddress]);

  useEffect(() => {
    setLatLng(propsLatLng)
  }, [propsLatLng]);
  

  const enterNewAddress = () => {
    setAddress({});
    setNewAddress(true);
    set_unmappedAddress(null);
  };

  const hideAddressEdit = (address_id) => {
    showAddressEdit[address_id] = false;
    setShowAddressEdit(showAddressEdit);
  };

  const cancelAddress = (address) => {
    if (!address || !address.id) {
      setNewAddress(false);
    } else {
      hideAddressEdit(address.id);
    }

    if (!!unmappedAddress) {
      set_unmappedAddress(null);
      setMappedAddress(null);
      if (!!reselectAddress) {
        reselectAddress();
      }
    }
    if (!!_unmappedAddress) {
      return setUnmappedAddress(null), setMappedAddress(null);
    }
  };

  const reselectAddress0 = (e) => {
    set_unmappedAddress(null);
    setMappedAddress(null);

    return reselectAddress(e);
  };

  const showAddressEdit0 = (address_id) => {
    showAddressEdit[address_id] = true;
    return setShowAddressEdit(showAddressEdit);
  };

  const updateAddress = (address, e) => {
    e.preventDefault();
    hideAddressEdit(address.id);
    const Address = AddressResource.get(Rails)
    return Address.update(
      address,
      (address) => {
        // M.toast({ html: "Address updated." });
        sweetalert2.fire({
          text: I18nRails.t("venue.alertmessage.address_saved_successfully"),
          icon: "success",
        });

          const idx = owner.addresses.findIndex(
            (addrss) => addrss.id === address.id
          );
          if (idx >= 0) {
            owner.addresses[idx] = address;
          }

          setOwner(owner);
      },
      (error) => {
        // M.toast({
        //   html: "Validation Failed." + error.errors[0].pretty_message,
        // });
        sweetalert2.fire({
          text: "Validation Failed." + error.errors[0].pretty_message,
          icon: "success",
        });
        showAddressEdit0(address.id);
      }
    );
  };

  const setDefaultAddress = (address, event) => {
    let oldDefaultAddress = address;
    if (!!owner.addresses) {
      owner.addresses.forEach(function(address) {
        if (!!address.default_address) {
          oldDefaultAddress = address;
        }
        return (address.default_address = false);
      });
    }

    address.default_address = true;

    const Address = AddressResource.get(Rails)
    Address.default_address(
      { id: address.id },
      (address) => {
        sweetalert2.fire({
          text: "Address has been added as default.",
          icon: "success",
        });
        // return M.toast({ html: "Address has been added as default." });
        return;
      },
      //@owner = @User.get {id: 'me'}
      function(error) {
        address.default_address = false;
        if (!!oldDefaultAddress) {
          oldDefaultAddress.default_address = true;
        }
        sweetalert2.fire({
          text: "Address could not be added as default.",
          icon: "error",
        });
        //return M.toast({ html: "Address could not be added as default." });
        return;
      }
    );
  };

  const saveMapAddress = (address) => {
    address = {
      lng: address.lng,
      lat: address.lat,
      city: address.city,
      country: address.country,
      street: address.street
        ? address.street
        : `latitude: ${ address.lat }, longitude: ${ address.lng }`,
      zip: address.zip,
      name_door: user.first_name + " " + user.last_name,
    };
    let fn;
    const Address = AddressResource.get(Rails);
    let tempAddresses = _this.state.owner.addresses;
    if (address.id) {
      fn = new Address(address).$update;
    } else {
      fn = new Address(address).$create;
    }
    return fn(
      (address) => {
        sweetalert2.fire({
          title: "Location ",
          text: I18nRails.t("venue.alertmessage.address_saved_successfully"),
          icon: "success",
        });

        tempAddresses.push(address);
        setShowModal(false)
        setOwner({
          ...owner, 
          addresses: tempAddresses
        })
      },
      (error) => {
        sweetalert2.fire({
          title: "Location Error",
          text: I18nRails.t("venue.alertmessage.address_could_not_mapped"),
          icon: "error",
        });
      }
    );
  };

  const cancelModal = (val) => {
    setShowModal(false);
  };

  const toggleShowAddressEdit = (address_id) => {
    showAddressEdit[address_id] = !showAddressEdit[
      address_id
    ];
    return setShowAddressEdit(showAddressEdit);
  };

  const isShowAddressEdit = (address_id) => {
    return !!showAddressEdit[address_id];
  };

  const destroyAddress = (address, event, stopPropagation) => {
    //event.preventDefault()
    if (!!stopPropagation) {
      event.stopPropagation();
    }
    const idx = owner.addresses.findIndex(
      (addrss) => addrss.id === address.id
    );
    if (idx > -1) {
      owner.addresses.splice(idx, 1);
    }
    setOwner(owner);

    const Address = AddressResource.get(Rails)
    Address.delete(
      { id: address.id },
      (success) => {
        // M.toast({ html: "Address deleted." });
        sweetalert2.fire({
          text: "Address deleted Succesfully.",
          icon: "success",
        });
        setSuccess(success);
      },

      (error) => {
        // M.toast({ html: "Address could not be deleted." });
        sweetalert2.fire({
          text: "Address could not be deleted.",
          icon: "error",
        });
        if (idx > -1) {
          owner.addresses.splice(idx, 0, address);
        }
        setOwner(owner);
        setError(error);
      }
    );
  };

  const addAddress = (address, e) => {
    if (!address.city && !isDenmark) {
      //M.toast({ html: "Please add city as well" });
      sweetalert2.fire({
        text: "Please add city as well.",
        icon: "info",
      });
      return;
    }

    const UserAddress = UserAddressResource.get(Rails);
    address = new UserAddress(address);

    address.country = Rails.locale_country;

    let idx = 0;
    if (!owner.addresses) {
      setOwner({ ...owner, addresses: [] });
    } else {
      idx = owner.addresses.length;
    }

    let fn = address.$update;
    if (!address.id) {
      fn = address.$create;
    }
    fn(
      (response) => {
        const savedAddress = response.address;
        //M.toast({ html: "Address Saved." });
        sweetalert2.fire({
          text: I18nRails.t("venue.alertmessage.address_saved"),
          icon: "success",
        });
        if (isShowAddressEdit(savedAddress.id)) {
          hideAddressEdit(savedAddress.id);

          setOwner({
            ...owner,
            addresses: owner.addresses.splice(idx, 0, savedAddress),
          })
          setAddress(savedAddress)
          setNewAddress(true);
        } else {
          setOwner({
            ...owner,
            addresses: owner.addresses.splice(idx, 0, savedAddress),
          })
          setAddress(savedAddress)
          setNewAddress(false);
        }
      },
      (error) => {
        // M.toast({

        //   html:
        //     "Address could not be mapped. Please pinpoint the exact coordinates.",
        // });

        sweetalert2.fire({
          text: I18nRails.t("venue.alertmessage.address_could_not_mapped"),
          icon: "error",
        });
        if (!address.lat && !!latLng) {
          address.lat = latLng.lat;
          address.lng = latLng.lng;
        }

        if (idx > -1) {
          setOwner({
            ...owner,
            addresses: owner.addresses.splice(idx, 1),
          })
          setNewAddress(false)
          set_unmappedAddress(address);
        } else {
          setAddress(address);
          setNewAddress(false);
          set_unmappedAddress(address);
        }
      }
    );
  };

  const onMarkerDragEnd = (coord) => {
    console.log("coord", coord);
  };

  return (
    <Component
      Rails={Rails}
      owner={owner}
      selectedAddress={selectedAddress}
      unmappedAddress={unmappedAddress}
      _unmappedAddress={_unmappedAddress}
      latLng={latLng}
      setLatLng={setLatLng}
      selectAddress={selectAddress}
      addAddress={addAddress}
      cancelAddress={cancelAddress}
      updateAddress={updateAddress}
      enterNewAddress={enterNewAddress}
      destroyAddress={destroyAddress}
      newAddress={newAddress}
      setDefaultAddress={setDefaultAddress}
      toggleShowAddressEdit={toggleShowAddressEdit}
      isShowAddressEdit={isShowAddressEdit}
      saveMapAddress={saveMapAddress}
      showModal={showModal}
      cancelModal={cancelModal}
      onMarkerDragEnd={onMarkerDragEnd}
    />
  );
};

export default withAddresses;