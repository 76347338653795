import { storage } from '../../react/bridge';
import { getComponentOptionValueFromOption } from '../models/component_option_value';
import { buildOrderItemFromMenuItem, calculateMenuItemPrice, orderItemMatchesSelection, orderItemOptionValueName } from '../models/order_item';
import { buildOrderItemIngredientsFromMenuItemIngredients } from '../models/order_item_ingredient';
import LocationResource from '../resources/location';
import OrderResource from '../resources/order';
import OrderItemResource from '../resources/order-item';
export default class CartService {
  static get(Rails) {
    return new CartService(storage, Rails);
  }

    constructor(storage, Rails) {
    this.Location = LocationResource.get(Rails);
    this.Order = OrderResource.get(Rails);
    this.OrderItem = OrderItemResource.get(Rails);
    this.localStorage = storage;
  }

  minimizeBasketData() {
    const updated_baskets = {};
    Object.keys(this.baskets).forEach(location_id => {
      var updated_basket;
      const basket = this.baskets[location_id];
      if (!!basket.location) {
        const { location } = basket;
        // console.log( 'adding', location )
        const location_min = {
          id: location.id,
          slug: location.slug,
          name: location.name,
          phone: location.phone,
          currency: location.currency,
          currency_symbol: location.currency_symbol,
          channels: [...location.channels],
          hosted_datas: [...location.hosted_datas],
          addresses: [...location.addresses],
          min_delivery_price: location.min_delivery_price,
          reviews_count: location.reviews_count,
          gst: location.gst,
          position: location.position,
          weekly_schedules: [...location.weekly_schedules],
          offers: [...location.offers],
          supported_delivery_payments: [...location.supported_delivery_payments],
          available_places_payment_methods: [...location.available_places_payment_methods],
          paypal_client_id: location.paypal_client_id
        };
        updated_basket = { ...basket, location_id: location.slug, location: location_min };
      } else {
        updated_basket = basket;
      }

      updated_baskets[location_id] = updated_basket;
    })
    return updated_baskets;
  }
  sync() {
    const minimizedBaskets = this.minimizeBasketData();
    this.localStorage.setItem('baskets', JSON.stringify(minimizedBaskets));
    return minimizedBaskets;
  }

  setVoucher(voucher, basket) {
    console.log('voucher', voucher);
    basket.voucher_code = voucher.code;
    basket.voucher = voucher;

    this.sync();
  }

  getAll() {
    if(this.baskets) return this.minimizeBasketData();
    this.baskets = this.localStorage.getItem('baskets');
    if (!this.baskets) {
      this.baskets = {};
    } else {
      if (typeof this.baskets === 'string') { this.baskets = JSON.parse(this.baskets); }
      const iterable = Object.keys(this.baskets);
      Object.keys(this.baskets).forEach(locationId => {
        const basket = this.baskets[locationId];
        if (!!basket.location) {
          basket.voucher_code = '';
        }
        basket.location_id = locationId;
      })
      for (let i = 0, index = i; i < iterable.length; i++, index = i) {
        //convert hash to location object
        const key = iterable[index];

      }
    }

    return this.baskets;
  }

  get(location_id, forCheckout) {
    if (forCheckout == null) { forCheckout = false; }
    this.getAll();
    if (!location_id) { location_id = 0; }
    if (!!this.baskets[location_id] && (this.baskets[location_id].length > 0)) {
      for (let item of this.baskets[location_id].items) {
        delete item.title;
      }
    } else if (!this.baskets[location_id]) {
      this.baskets[location_id] = { location_id, location: null, items: [], totals: { numberOfCartItems: 0 } };
    }

    return this.baskets[location_id];
  }

  set(location_id, basket) {
    const updatedBaskets = {...this.baskets}
    updatedBaskets[location_id] = basket;
    this.baskets = updatedBaskets;
  }

  existsMenuItem(menu_item, location_id) {
    const basket = this.get(location_id);
    return this.indexOfMenuItem(basket, menu_item) > -1;
  }


  //returns the last index of MenuItem if found
  indexOfMenuItem(basket, menu_item) {
    if (basket.items.length < 1) { return -1; }
    const start_index = basket.items.length - 1;
    for (let index = start_index, asc = start_index <= 0; asc ? index <= 0 : index >= 0; asc ? index++ : index--) {
      const order_item = basket.items[index];
      if ((!!order_item.item_type === 'MenuItem') && (order_item.item_id === menu_item.id)) { return index; }
    }
    return -1;
  }

  exists(menu_item, component_option_value, selected_menu_item_ingredients, location_id) {
    //TODO fix caller to send option_value
    if (!component_option_value && menu_item.option) {
      component_option_value = getComponentOptionValueFromOption(menu_item.option);
    }
    if (!selected_menu_item_ingredients) {
      ({
        selected_menu_item_ingredients
      } = menu_item);
    }
    const basket = this.get(location_id);
    return this.indexOfSelection(basket, menu_item, component_option_value, selected_menu_item_ingredients) > -1;
  }

  indexOfSelection(basket, menu_item, component_option_value, selected_menu_item_ingredients) {
    let index = 0;
    for (let order_item of basket.items) {
      if (orderItemMatchesSelection(
        order_item, menu_item, component_option_value, 
        buildOrderItemIngredientsFromMenuItemIngredients(selected_menu_item_ingredients)
      )) {
        return index;
      }
      index += 1;
    }
    return -1;
  }

  indexOf(basket, order_item) {
    let index = 0;
    for (let basket_item of basket.items) {
      const order_item_to_find = basket_item;
      if (orderItemMatchesSelection(order_item_to_find, order_item.menu_item, order_item.component_option_value, order_item.order_item_ingredients)) {
        return index;
      }
      index += 1;
    }
    return -1;
  }


  removeItem(menu_item, component_option_value, selected_menu_item_ingredients, location_id) {
    //TODO fix caller to send option_value
    if (!component_option_value && menu_item.option) {
      component_option_value = getComponentOptionValueFromOption(menu_item.option);
    }
    if (!selected_menu_item_ingredients) {
      ({
        selected_menu_item_ingredients
      } = menu_item);
    }
    const basket = this.get(location_id);
    console.log('removeItem Service basket', basket);
    const index = this.indexOfSelection(basket, menu_item, component_option_value, selected_menu_item_ingredients);
    basket.items.splice(index, 1);
    if (!(basket.items.length > 0)) {
      basket.location = null;
    }

    return this.sync();

  }

  //deprecated addMenuItem, and USE addOrderItem instead (currently being used from LocationController
  addMenuItem(location, menu_item, quantity, component_option_value, selected_menu_item_ingredients, menu) {
    let order_item;
    const basket = this.get(location.slug);
    basket.location = location; //refresh location data
    if (basket.items.length > 0) {
      const index = this.indexOfSelection(basket, menu_item, component_option_value, selected_menu_item_ingredients, menu);
      if (index > -1) {
        order_item = basket.items[index];
        order_item.weighted_number += quantity;
        order_item.unit_price = calculateMenuItemPrice(order_item.menu_item, order_item.component_option_value,
          order_item.order_item_ingredients);
        order_item.price = order_item.weighted_number * order_item.unit_price;
      } else {
        order_item = buildOrderItemFromMenuItem(menu_item, component_option_value,
          selected_menu_item_ingredients, quantity, basket.location, menu);
        basket.items.push(order_item);
      }
    } else {
      order_item = buildOrderItemFromMenuItem(menu_item, component_option_value,
        selected_menu_item_ingredients, quantity, basket.location, menu);
      basket.items.push(order_item);
    }

    this.sync();
    return true;
  }

  //USE addOrderItem instead of addMenuItem
  addOrderItem(location, order_item) {
    const basket = this.get(location.slug);
    basket.location = location;
    this.addOrderItemToBasket(basket, order_item)

    this.sync();
    return true;
  }

  addOrderItemToBasket(basket, order_item) {
    if (basket.items.length > 0) {
      const index = this.indexOf(basket, order_item);
      if (index > -1) {
        const order_item_basket = basket.items[index];
        order_item_basket.weighted_number += order_item.weighted_number;
        order_item_basket.unit_price = calculateMenuItemPrice(order_item_basket.menu_item,
          order_item_basket.component_option_value, order_item_basket.order_item_ingredients);
        order_item_basket.price = order_item_basket.weighted_number * order_item_basket.unit_price;
      } else {
        basket.items.push(this.OrderItem.fromOrderItem(order_item));
      }
    } else {
      basket.items.push(this.OrderItem.fromOrderItem(order_item));
    }    
  }

  reduceQuantityAtIndex(basket, index, order_item) {
    if (!order_item) { order_item = {...basket.items[index]}; }
    const weighted_single = order_item.menu_item.weighted_ratio > 0 ? (1.0 / order_item.menu_item.weighted_ratio) : 1;
    if (order_item.weighted_number > weighted_single) {
      order_item.weighted_number -= weighted_single;
      order_item.unit_price = calculateMenuItemPrice(order_item.menu_item, order_item.component_option_value,
        order_item.order_item_ingredients);
      order_item.price = order_item.weighted_number * order_item.unit_price;
      basket.items[index] = order_item;
    } else {
      basket.items.splice(index, 1);
      if (!(basket.items.length > 0)) {
        basket.location = null;
      }
    }
    //basket.location_id = null
    this.sync();
  }

  increaseQuantity(location_id, order_item) {
    console.log('increase quant called');
    const basket = this.get(location_id);
    const index = this.indexOf(basket, order_item);
    if (index > -1) {
      this.increaseBasketItemQuantity(basket, index)
      this.sync();
    }
    return true;
  }

  increaseBasketItemQuantity(basket, itemIndex) {
    const orderItem = {...basket.items[itemIndex]};
    const weighted_single = orderItem.menu_item.weighted_ratio > 0 ? (1.0 / orderItem.menu_item.weighted_ratio) : 1;
    orderItem.weighted_number += weighted_single;
    orderItem.unit_price = calculateMenuItemPrice(orderItem.menu_item, orderItem.component_option_value,
      orderItem.orderItem_ingredients);
    orderItem.price = orderItem.weighted_number * orderItem.unit_price;    
    basket.items[itemIndex] = orderItem
    return true;
  }

  decreaseBasketItemQuantity(basket, itemIndex) {
    const orderItem = {...basket.items[itemIndex]};
    this.reduceQuantityAtIndex(basket, itemIndex, orderItem)
    return true
  }

  decreaseQuantity(location_id, order_item) {
    const basket = this.get(location_id);
    const updatedBasket = {...basket, items: [...basket.items]}
    const index = this.indexOf(updatedBasket, order_item);
    if (index > -1) {
      this.reduceQuantityAtIndex(updatedBasket, index, order_item);
    }

    return true;
  }

  addQuantity(location, menu_item, component_option_value, selected_menu_item_ingredients, menu) {
    if (!component_option_value && menu_item.option) {
      component_option_value = getComponentOptionValueFromOption(menu_item.option);
    }
    if (!selected_menu_item_ingredients) {
      ({
        selected_menu_item_ingredients
      } = menu_item);
    }
    return this.addMenuItem(location, menu_item, 1, component_option_value, selected_menu_item_ingredients, menu);
  }

  subtractQuantity(location, menu_item, component_option_value, selected_menu_item_ingredients) {
    if (!component_option_value && menu_item.option) {
      component_option_value = getComponentOptionValueFromOption(menu_item.option);
    }
    if (!selected_menu_item_ingredients) {
      ({
        selected_menu_item_ingredients
      } = menu_item);
    }

    const basket = this.get(location.slug);
    const index = this.indexOfSelection(basket, menu_item, component_option_value, selected_menu_item_ingredients);
    if (index > -1) {
      this.reduceQuantityAtIndex(basket, index);
    }
    return true;
  }

  subtractMenuItemQuantity(location, menu_item) {
    const basket = this.get(location.slug);
    const index = this.indexOfMenuItem(basket, menu_item);
    if (index > -1) {
      this.reduceQuantityAtIndex(basket, index);
    }
    return true;
  }

  getTotal(location_id) {
    let total = 0;
    const basket = this.get(location_id);
    for (let basket_item of basket.items) {
      total += parseFloat(basket_item.price);
    } //*basket_item.weighted_number
    return total;
  }

  numberOfItems(location_id) {
    let basket, item, order_item;
    let number = 0;
    if (!!location_id) {
      basket = this.get(location_id);
      if (!!basket) {
        for (item of basket.items) {
          if (item.type !== 'OrderItemDelivery') {
            order_item = item;
            number += order_item.weighted_number;
          }
        }
        //remove OrderItemDelivery if menu_items have been removed
        basket.totals.numberOfCartItems = number;
        if (number < 1) { basket.items = []; }
      }
    } else {
      this.getAll();
      for (let k in this.baskets) {
        basket = this.baskets[k];
        if (!!basket.location_id) {
          for (item of basket.items) {
            if (item.type !== 'OrderItemDelivery') {
              order_item = item;
              number += order_item.weighted_number;
            }
          }
        }
      }
    }

    return number;
  }

  clearCart(location_id) {
    const basket = this.get(location_id);
    //basket.location = null
    basket.items = [];
    basket.totals = { gst: 0, numberOfCartItems: 0 };
    var storageBaskets = JSON.parse(this.localStorage.getItem('baskets'));
    delete storageBaskets[location_id];
    console.log('storage Basekts', storageBaskets);
    this.localStorage.setItem('baskets', JSON.stringify(storageBaskets));
    if (!!this.localStorage.getItem('baskets')) { delete this.localStorage.removeItem('baskets'); }
    this.sync();
  }

  clearCartForOrderLocation(order) {
    this.getAll();
    const {
      location_id
    } = order;
    return (() => {
      const result = [];
      for (let k in this.baskets) {
        const basket = this.baskets[k];
        if (!!basket.location_id === location_id) {
          delete this.baskets[k];
          result.push(this.sync());
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  }


  findOrderId(order_id) {
    if (!this.orders) { return null; }
    for (let order of this.orders) {
      if (order_id === order.id) { return order; }
    }
    return null;
  }

  getOrderId(location_id) {
    if (!!this.orders && !!this.orders[location_id]) { return this.orders[location_id].id; }
    return -1;
  }

  getOrder(location_id) {
    return this.orders[location_id];
  }

  setOrder(order) {
    if (!this.orders) { this.orders = {}; }
    return this.orders[order.location_id] = order;
  }
}

