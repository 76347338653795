import React, { useState, useRef, useEffect } from 'react';
import InfiniteItems from '../InfiniteItems';
import OrderResource from '../../resources/order';
import { I18nRails } from '../../shared/rails-i18n-js';

const withOrders = (Component) => ({
  Rails,
  fdOrders,
  ...others
}) => {
  const [page0, setPage] = useState(0);
  const [per_page, setPer_page] = useState(10);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [doListenForUpdates, setDoListenForUpdates] = useState(false);
  const [location_id, setLocation_id] = useState();
  const [orders0, setOrders] = useState();

  const hasOpenOrders = () => {
    if (!!infiniteOrdersRef.current.items) {
      for (let order of infiniteOrdersRef.current.items) {
        // switch order.state
        //   when "received" then return true
        //   when "accepted" then return true
        if (order[0].isOpen()) { return true; }
      }
      return false;
    }
  };

  const listenForUpdates = (location_id) => {
    setTimeout(() => {
      if (doListenForUpdates) {
        const params = { page: page0, per_page: per_page };
        if (!!location_id) { params.location_id = location_id; }
        const Order = OrderResource.get(Rails);
        Order.recent({}, params, orders => {
          for (let order of orders) {
            if (typeof (orderIndicesRef.current[order.id]) === "number") {
              //order already exists
              const stored_order = orders0[orderIndicesRef.current[order.id]];
              if ((order.id === stored_order.id) && (order.state !== stored_order.state)) {
                orders0[orderIndicesRef.current[order.id]] = order;
                setOrders(orders0);
              }
            } else {
              //push order and record index
              orders0.push(order);
              orderIndicesRef.current[order.id] = orders0.length - 1;
              setOrders(orders0);
            }
          }
          listenForUpdates(location_id);
        }
          , failure => {
            console.error('failure: ', failure);
          });
      }
    }
      //alert('Error fetching orders. Please refresh')
      , 15000);
  };

  const orderStateColor = (state) => {
    switch (state) {
      case 'received': return 'yellow';
      case 'rejected': return 'red';
      case 'accepted': return 'green';
      default: return 'blue';
    }
  };

  const loadMoreOrders = () => {
    if (!!loadingOrders) { return; }
    const page = page0 + 1;
    setLoadingOrders(true);
    setPage(page);
    const Order = OrderResource.get(Rails);
    Order.query({ page, per_page: per_page }, orders => {
      let index = 0;
      orders = orders.filter(order => {
        let doSelect = true;
        if (typeof (orderIndicesRef.current[order.id]) === 'number') {
          //order already exists
          const stored_order = infiniteOrdersRef.current.items[orderIndicesRef.current[order.id]];
          if (order.id === stored_order.id) { //&& order.state != stored_order.state
            infiniteOrdersRef.current.items[orderIndicesRef.current[order.id]] = order;
          }
          doSelect = false;
        } else {
          //push order and record index
          orderIndicesRef.current[order.id] = infiniteOrdersRef.current.items.length + index;
        }

        index += 1;
        return doSelect;
      });
      const hasMore = orders.length > 0;
      infiniteOrdersRef.current.addItems(orders);
      setLoadingOrders(false);
      setHasMore(hasMore);
      setDoListenForUpdates(hasOpenOrders());
    });
  };

  const orderIndicesRef = useRef({});
  const config = {
    page: 0,
    per_page: 20,
    list_size: 1,
    filter: {},
    locale_country: Rails.locale_country
  };
  const infiniteOrdersRef = useRef(new InfiniteItems(OrderResource.get(Rails), config));

  useEffect(() => {
    if (!!fdOrders) {
      const orders = JSON.parse(fdOrders);
      //check to ensure that if data is cached from server, it is
      //for the location that is currently referenced
      if ((orders.length > 0) && (!location_id || (location_id === orders.location_id))) {
        config.page = (page0 = 1);
        const Order = OrderResource.get(Rails);
        infiniteOrdersRef.current.addItems(orders.map(order => new Order(order)));
      }
    } 
    if ((infiniteOrdersRef.current.items.length < 1) && hasMore) {
      loadMoreOrders();
    }
  }, []);

  return (
    <Component 
      hasOpenOrders={hasOpenOrders}
      fdOrders={fdOrders}
      Rails={Rails}
      loadingOrders={loadingOrders}
      setLoadingOrders={setLoadingOrders}
      hasMore={hasMore}
      infiniteOrdersRef={infiniteOrdersRef}
      orderIndicesRef={orderIndicesRef}
      setHasMore={setHasMore}
      doListenForUpdates={doListenForUpdates}
      setDoListenForUpdates={setDoListenForUpdates}
      infiniteOrders={infiniteOrdersRef.current}
      loadMoreOrders={loadMoreOrders}
      listenForUpdates={listenForUpdates}
      {...others}
    />
  )
};

export default withOrders;
