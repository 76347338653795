import * as qz from "qz-tray";

import { sha256 } from "js-sha256";
import createHash from "sha.js";
import { createSign } from 'crypto-browserify'
import { Crypto } from "@peculiar/webcrypto";
import { w3cwebsocket } from "websocket";
import packageInfo from "../../../package.json";
import { osName, isElectron } from "react-device-detect";
import { matchPath } from "react-router";
import { connectRouter } from "connected-react-router";
import { createReduxHistoryContext } from "redux-first-history";
import { firebase } from "../react-pos/firebase.js";
import { encryptData, decryptData } from "../react-pos/utils/encryption-decryption";
import html2pdf from 'html2pdf.js/src/index'
import { json2csv } from "json-2-csv";

// TODO uncomment on React-Native side
// import Gzip from '@fengweichong/react-native-gzip';
// import RNFS from 'react-native-fs';
// import {Alert} from 'react-native';
// import fileType from 'react-native-file-type';
// import RNBackgroundDownloader from '@kesha-antonov/react-native-background-downloader';
// import {unzip, zip} from 'react-native-zip-archive';
// import RNFetchBlob from 'rn-fetch-blob';
// import { Platform } from 'react-native';
// const osName = Platform.OS

// TODO comment on React-Native side
const Gzip = {}
const RNFS = {}
const Alert = {}
const fileType = {}
const RNBackgroundDownloader = {}
const unzip = {}
const zip = {}
const RNFetchBlob = {}

// import { getOrderDbConnection, getLocationDbConnection } from "../react-pos-core/sql/connection";
// import fpe from 'node-fpe';

import localforage from "localforage/src/localforage";

const isReactNative = false;

const isFoodnerdWeb = true;

localforage.config({
  driver: localforage.INDEXEDDB,
  name: packageInfo.name,
  version: 1,
  storeName: "user"
});

const storage = {
  setItem: function(key, value) {
    // return Promise.resolve().then(function() {
      return localStorage.setItem(key, value);
    // });
  },
  getItem: function(key) {
    // return Promise.resolve().then(function() {
      return localStorage.getItem(key);
    // });
  }

};
// export { qzConnection, qz };

export { osName, isElectron, isReactNative, isFoodnerdWeb };
export { matchPath, createReduxHistoryContext, connectRouter };

const getOrderDbConnection = () => { throw 'not implemented'; };
const getLocationDbConnection = () => { throw 'not implemented'; };

export { firebase, firestore, getOrderDbConnection, getLocationDbConnection };

export function getOnlineStatus() {
  return typeof navigator !== "undefined" && typeof navigator.onLine === "boolean" ? navigator.onLine : true;
}

const fpe = () => { throw 'not implemented'; };
export { qz, sha256, w3cwebsocket, localforage, storage, fpe };

export { createHash, createSign, Crypto }

export const KJUR = () => { throw 'not implemented'; };
export const KEYUTIL = () => { throw 'not implemented'; };
export const stob64 = () => { throw 'not implemented'; };
export const hextorstr = () => { throw 'not implemented'; };

// export { KJUR, KEYUTIL, stob64, hextorstr } from "jsrsasign";

export const writeFile = () => {throw 'only for react-native'}
export const defaultFilePath = false

const getDatabase = () => {}
const defaultMenuPath = {}

export { getDatabase, defaultMenuPath };

export { Gzip, RNFS, Alert, fileType, RNBackgroundDownloader, zip, unzip, RNFetchBlob };

const dbFilePath = ''
const Platform = {}
const exists = () => {}
const SQLite = {}
const closeDb = () => {}

export {dbFilePath, Platform, exists, SQLite, closeDb }

export { encryptData, decryptData}

export {json2csv, html2pdf }
